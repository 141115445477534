<template>
  <v-container fluid>
    <v-data-table
      :headers="headers"
      :items="items.items"
      item-key="id"
      class="elevation-1"
      :items-per-page="25"
      :loading="loading"
      :options.sync="options"
      :server-items-length="items.meta.totalItems"
      :footer-props="{
        'items-per-page-options': rowsPerPageItemsGlobal
      }"
    >
      <template v-slot:top>
        <div class="d-inline-flex justify-space-between align-center pr-2" style="width: 100%">
          <div class="d-inline-flex align-center">
            <v-select
              v-model="type"
              class="pt-2 pl-2"
              :items="types"
              :label="$lang.labels.type"
              outlined
              dense
            ></v-select>
            <v-menu
              v-if="['day', 'month'].includes(key)"
              ref="menu"
              v-model="menu"
              :close-on-content-click="true"
              transition="scale-transition"
              offset-y
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="date"
                  :label="$lang.labels.date"
                  readonly
                  outlined
                  dense
                  class="pt-2 pl-2"
                  v-bind="attrs"
                  v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="date"
                no-title
                :type="key === 'day' ? 'date' : key"
                scrollable
                max="allowedYears"
              >

              </v-date-picker>
            </v-menu>
            <v-select
              v-else
              v-model="date"
              class="pt-2 pl-2"
              :items="yearOptions"
              :label="$lang.labels.date"
              outlined
              dense
            ></v-select>
            <div class="pb-2">
              <v-btn
                text
                color="primary"
                class="ml-2"
                @click="$emit('fetchTelemetries', { options: options, date: date, key: key })"
              ><v-icon>mdi-refresh</v-icon></v-btn>
            </div>
          </div>
        </div>
      </template>

      <template v-slot:item.processName="{ item }">
        <div>
          <v-btn
            text
            small
            color="primary"
            @click="goToProcess(item)"
          >{{ item.processName }}</v-btn>
        </div>
      </template>

      <template v-slot:item.count="{ item }">
        <div>{{ item.count.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".") }}</div>
      </template>
    </v-data-table>
  </v-container>
</template>

<script>
import { bus } from '@/main'
import { mapState } from 'vuex'

export default {
  props: {
    items: {
      type: Object,
      default: () => {}
    },
    loading: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      allowedYears: '',
      lock: true,
      type: this.$lang.labels.daily,
      date: new Date().toISOString().substr(0, 10),
      key: 'day',
      menu: false,
      yearOptions: [...Array(30).keys()].map((i) => Number(new Date().toISOString().substr(0, 4) - i)),
      options: {},
      totalItems: 0,
      headers: [
        { text: this.$lang.labels.processName, value: 'processName', sortable: false },
        { text: this.$lang.labels.count,align: 'right', value: 'count', sortable: false }
      ],
      types: [this.$lang.labels.daily, this.$lang.labels.monthly, this.$lang.labels.yearly]
    }
  },
  computed: {
    ...mapState('app', ['rowsPerPageItemsGlobal', 'userSettings'])
  },
  watch: {
    options: {
      handler () {
        if (!this.lock) this.$emit('fetchTelemetries', { options: this.options, date: this.date, key: this.key })
        this.savePreFill()
      },
      deep: true
    },
    type: {
      handler () {
        switch (this.type) {
        case this.$lang.labels.daily:
          this.key = 'day'
          this.date = new Date().toISOString().substr(0, 10)
          break
        case this.$lang.labels.monthly:
          this.key = 'month'
          this.date = new Date().toISOString().substr(0, 7)
          break
        case this.$lang.labels.yearly:
          this.key = 'year'
          this.date = Number(new Date().toISOString().substr(0, 4))
          break
        }
        this.savePreFill()
      }
    },
    date: {
      handler () {
        if (!this.lock) this.$emit('fetchTelemetries', { options: this.options, date: this.date, key: this.key })
        this.savePreFill()
      }
    }
  },
  mounted() {
    bus.$on('refreshData', (name) => {
      if (!this.lock && name === this.$route.name) this.$emit('fetchTelemetries', { options: this.options, date: this.date, key: this.key })
    })
  },
  created() {
    const today = new Date()
    const dd = String(today.getDate()).padStart(2, '0')
    const mm = String(today.getMonth() + 1).padStart(2, '0')
    const yyyy = today.getFullYear() - 10

    this.allowedYears = mm + '-' + dd + '-' + yyyy

    if (localStorage.preFillData) {
      const preFill = JSON.parse(localStorage.preFillData)

      if (preFill && preFill.telemetryList) {
        this.type = preFill.telemetryList.type
        this.date = preFill.telemetryList.date
        this.key = this.type === this.$lang.labels.daily ? 'day' : this.type === this.$lang.labels.monthly ? 'month' : 'year'
      } else {
        preFill.telemetryList.type = this.$lang.labels.daily
        preFill.telemetryList.date = new Date().toISOString().substr(0, 10)
      }
    }
    this.$emit('fetchTelemetries', { options: this.options, date: this.date, key: this.key })
    setTimeout(() => this.lock = false, 100)
  },
  methods: {
    savePreFill() {
      if (!localStorage.preFillData) localStorage.preFillData = '{}'
      if (localStorage.preFillData) {
        const preFill = JSON.parse(localStorage.preFillData)

        preFill.telemetryList = {
          type: this.type || this.$lang.labels.daily,
          date: this.date || new Date().toISOString().substr(0, 10)
        }
        localStorage.preFillData = JSON.stringify(preFill)
      }
    },
    goToProcess(item) {
      const routeData = this.$router.resolve({
        name: 'processEdit',
        params: { id: item.processId }
      })

      window.open(routeData.href, '_blank')
    }
  }
}
</script>
