var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":""}},[_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.items.items,"item-key":"id","items-per-page":25,"loading":_vm.loading,"options":_vm.options,"server-items-length":_vm.items.meta.totalItems,"footer-props":{
      'items-per-page-options': _vm.rowsPerPageItemsGlobal
    }},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('div',{staticClass:"d-inline-flex justify-space-between align-center pr-2",staticStyle:{"width":"100%"}},[_c('div',{staticClass:"d-inline-flex align-center"},[_c('v-select',{staticClass:"pt-2 pl-2",attrs:{"items":_vm.types,"label":_vm.$lang.labels.type,"outlined":"","dense":""},model:{value:(_vm.type),callback:function ($$v) {_vm.type=$$v},expression:"type"}}),(['day', 'month'].includes(_vm.key))?_c('v-menu',{ref:"menu",attrs:{"close-on-content-click":true,"transition":"scale-transition","offset-y":"","min-width":"auto"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
    var on = ref.on;
    var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({staticClass:"pt-2 pl-2",attrs:{"label":_vm.$lang.labels.date,"readonly":"","outlined":"","dense":""},model:{value:(_vm.date),callback:function ($$v) {_vm.date=$$v},expression:"date"}},'v-text-field',attrs,false),on))]}}],null,false,1494230577),model:{value:(_vm.menu),callback:function ($$v) {_vm.menu=$$v},expression:"menu"}},[_c('v-date-picker',{attrs:{"no-title":"","type":_vm.key === 'day' ? 'date' : _vm.key,"scrollable":"","max":"allowedYears"},model:{value:(_vm.date),callback:function ($$v) {_vm.date=$$v},expression:"date"}})],1):_c('v-select',{staticClass:"pt-2 pl-2",attrs:{"items":_vm.yearOptions,"label":_vm.$lang.labels.date,"outlined":"","dense":""},model:{value:(_vm.date),callback:function ($$v) {_vm.date=$$v},expression:"date"}}),_c('div',{staticClass:"pb-2"},[_c('v-btn',{staticClass:"ml-2",attrs:{"text":"","color":"primary"},on:{"click":function($event){return _vm.$emit('fetchTelemetries', { options: _vm.options, date: _vm.date, key: _vm.key })}}},[_c('v-icon',[_vm._v("mdi-refresh")])],1)],1)],1)])]},proxy:true},{key:"item.processName",fn:function(ref){
    var item = ref.item;
return [_c('div',[_c('v-btn',{attrs:{"text":"","small":"","color":"primary"},on:{"click":function($event){return _vm.goToProcess(item)}}},[_vm._v(_vm._s(item.processName))])],1)]}},{key:"item.count",fn:function(ref){
    var item = ref.item;
return [_c('div',[_vm._v(_vm._s(item.count.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".")))])]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }